export const ExclamationTriangleIcon = () => (
  <svg fill='none' height={16} width={16} xmlns='http://www.w3.org/2000/svg'>
    <path d='M0 0h16v16H0z' fill='#fff' fillOpacity={0.01} />
    <path
      clipRule='evenodd'
      d='M9.008.65a1.173 1.173 0 0 0-2.016 0L.172 12.092a1.173 1.173 0 0 0 1.008 1.774h13.64c.91 0 1.474-.992 1.008-1.774L9.008.649Zm-1.1.545a.107.107 0 0 1 .184 0l6.82 11.444a.107.107 0 0 1-.092.161H1.18a.107.107 0 0 1-.092-.161l6.82-11.444Zm-.626 3.59a.719.719 0 1 1 1.436 0l-.164 4.268a.554.554 0 0 1-1.108 0l-.164-4.268Zm1.518 6.39a.8.8 0 1 1-1.6 0 .8.8 0 0 1 1.6 0Z'
      fill='#C10030'
      fillOpacity={0.859}
      fillRule='evenodd'
    />
  </svg>
);
