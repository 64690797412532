export const MagnifyingGlassIcon = ({ className }: { className?: string }) => (
  <svg
    aria-hidden='true'
    className={
      className ? className : 'w-6 h-6 mb-4 text-gray-500 dark:text-gray-400'
    }
    fill='none'
    height='20'
    viewBox='0 0 20 20'
    width='20'
  >
    <rect fill='white' fillOpacity='0.01' height='20' width='20' />
    <path
      clipRule='evenodd'
      d='M13.3337 8.66666C13.3337 11.244 11.2443 13.3333 8.66699 13.3333C6.08966 13.3333 4.00033 11.244 4.00033 8.66666C4.00033 6.08932 6.08966 3.99999 8.66699 3.99999C11.2443 3.99999 13.3337 6.08932 13.3337 8.66666ZM12.4121 13.3545C11.3857 14.1757 10.0837 14.6667 8.66699 14.6667C5.35329 14.6667 2.66699 11.9804 2.66699 8.66666C2.66699 5.35295 5.35329 2.66666 8.66699 2.66666C11.9807 2.66666 14.667 5.35295 14.667 8.66666C14.667 10.0833 14.1761 11.3853 13.3549 12.4118L17.1385 16.1952C17.3987 16.4556 17.3987 16.8777 17.1385 17.1381C16.8781 17.3984 16.4559 17.3984 16.1955 17.1381L12.4121 13.3545Z'
      fillRule='evenodd'
      stroke='currentColor'
    />
  </svg>
);
