import Link from 'next/link';

import { MagnifyingGlassIcon } from './icons/MagnifyingGlassIcon';
import { RightArrowIcon } from './icons/RightArrowIcon';
import { UploadIcon } from './icons/UploadIcon';

export const SupportButtonSection = () => {
  return (
    <div className='flex flex-col w-full gap-4'>
      <Link
        className='group flex h-fit lg:h-[56px] lg:overflow-hidden lg:transition-height lg:delay-150 lg:duration-500 lg:ease-in-out lg:hover:h-[84px] flex-col text-center justify-start w-auto lg:w-full items-center rounded-[100px] lg:gap-0 gap-3 lg:p-0 p-2 bg-[#562F8C] text-[#FDFCFD]'
        href='/support/ask'
      >
        <div className='flex px-4 py-2 lg:px-8 lg:py-0 text-[18px] justify-between items-center lg:min-h-[56px] w-full'>
          <p className='font-light'>Ask a question</p>
          <RightArrowIcon className='w-4 h-4 text-white fill-white' />
        </div>
        <p className='hidden w-full px-2 lg:px-8 pb-3 lg:flex lg:justify-start lg:items-start lg:h-[36px] text-[12px] mt-[-8px] opacity-0 group-hover:opacity-100 transition-opacity delay-150 duration-500 text-[#F5EFFF] font-light text-start'>
          Visit costs, benefits, prescriptions, and more.
        </p>
      </Link>
      <Link
        className='group flex h-fit lg:h-[56px] lg:overflow-hidden lg:transition-height lg:delay-150 lg:duration-500 lg:ease-in-out lg:hover:h-[84px] flex-col text-center justify-start w-auto lg:w-full items-center rounded-[100px] lg:gap-0 gap-3 lg:p-0 p-2 bg-[#29004C] text-[#FDFCFD]'
        href='/support/billupload'
      >
        <div className='flex px-4 py-2 lg:px-8 lg:py-0 text-[18px] justify-between items-center lg:min-h-[56px] w-full'>
          <p className='font-light'>Upload a bill</p>
          <UploadIcon className='w-4 h-4 text-white' />
        </div>
        <p className='hidden w-full px-2 lg:px-8 pb-3 lg:flex lg:justify-start lg:items-start lg:h-[36px] text-[12px] mt-[-8px] opacity-0 group-hover:opacity-100 transition-opacity delay-150 duration-500 text-[#F5EFFF] font-light text-start'>
          Submit bills for review & claim submission.
        </p>
      </Link>
      <Link
        className='group flex h-fit lg:h-[56px] lg:overflow-hidden lg:transition-height lg:delay-150 lg:duration-500 lg:ease-in-out lg:hover:h-[84px] flex-col text-center justify-start w-auto lg:w-full items-center rounded-[100px] lg:gap-0 gap-3 lg:p-0 p-2 bg-[#E6D5FF] text-[#29004C]'
        href='/support/findadoctor'
      >
        <div className='flex px-4 py-2 lg:px-8 lg:py-0 text-[18px] justify-between items-center lg:min-h-[56px] w-full'>
          <p className='font-light'>Find a doctor</p>
          <MagnifyingGlassIcon className='w-4 h-4 text-[#29004C]' />
        </div>
        <p className='hidden w-full px-2 lg:px-8 pb-3 lg:flex lg:justify-start lg:items-start lg:h-[36px] text-[12px] mt-[-8px] opacity-0 group-hover:opacity-100 transition-opacity delay-150 duration-500 text-[#29004C] font-light text-start'>
          Doctors & specialists based on your needs.
        </p>
      </Link>
    </div>
  );
};
